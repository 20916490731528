import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import React, {FC, useState, useEffect} from 'react'

import moment from 'moment'

const DashboardWrapper = () => {
  const intl = useIntl()

  const [data, setData] = React.useState([])

  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    // Her 1 saniyede bir sürekli olarak şu anın zamanını güncelle
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const getElapsedTime = (startTime: Date): string => {
    const startDate = new Date(startTime)
    const timeDifference = currentTime.getTime() - startDate.getTime()
    const secondsElapsed = Math.floor(timeDifference / 1000)
    const hours = Math.floor(secondsElapsed / 3600)
    const minutes = Math.floor((secondsElapsed % 3600) / 60)
    return `${hours} saat ${minutes} dakika`
  }

  useEffect(() => {}, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <div>
        <div className='row g-6 g-xl-9 mb-6 mb-xl-9 mt-5 px-5'>selam</div>
      </div>
    </>
  )
}

export {DashboardWrapper}
