import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {DashboardWrapper as DashboardPage} from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = (menus: Array<string>) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AboutUsPage = lazy(() => import('../pages/aboutus/Page'))
  const AboutUsPhotoPage = lazy(() => import('../pages/aboutusphoto/Page'))
  const ReportPage = lazy(() => import('../pages/report/Page'))
  const ManagementTeamPage = lazy(() => import('../pages/managementteam/Page'))
  const EventPage = lazy(() => import('../pages/event/Page'))
  const ActivityPage = lazy(() => import('../pages/activity/Page'))
  const GemologyPage = lazy(() => import('../pages/gemology/Page'))
  const GemologyPhotoPage = lazy(() => import('../pages/gemologyphoto/Page'))
  const GemPricePage = lazy(() => import('../pages/gemprice/Page'))
  const RepairPricePage = lazy(() => import('../pages/repairprice/Page'))
  const GoldReportPage = lazy(() => import('../pages/goldreport/Page'))
  const GoldReportPagePage = lazy(() => import('../pages/goldreportpage/Page'))
  const AdvertisementPage = lazy(() => import('../pages/advertisement/Page'))
  const EmployeeAdPage = lazy(() => import('../pages/employeead/Page'))
  const StoreAdPage = lazy(() => import('../pages/storead/Page'))
  const StoreAdPhotoPage = lazy(() => import('../pages/storeadphoto/Page'))
  const MaterialAdPage = lazy(() => import('../pages/materialad/Page'))
  const MaterialAdPhotoPage = lazy(() => import('../pages/materialadphoto/Page'))
  const ApplicationAdPage = lazy(() => import('../pages/applicationad/Page'))
  const ApplicationFormPage = lazy(() => import('../pages/applicationform/Page'))
  const GoldPricePage = lazy(() => import('../pages/goldprice/Page'))
  const AdvertPage = lazy(() => import('../pages/advert/Page'))
  const SliderPhotoPage = lazy(() => import('../pages/sliderphoto/Page'))
  const OfficePage = lazy(() => import('../pages/office/Page'))
  const UserPage = lazy(() => import('../pages/applicationuser/Page'))

  return (
    <Routes>
      <Route element={<MasterLayout {...(menus || [])} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />

        <Route path='dashboard' element={<DashboardPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='office/*'
          element={
            <SuspensedView>
              <OfficePage />
            </SuspensedView>
          }
        />
        <Route
          path='aboutus/*'
          element={
            <SuspensedView>
              <AboutUsPage />
            </SuspensedView>
          }
        />
        <Route
          path='aboutusphoto/*'
          element={
            <SuspensedView>
              <AboutUsPhotoPage />
            </SuspensedView>
          }
        />
        <Route
          path='report/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='managementteam/*'
          element={
            <SuspensedView>
              <ManagementTeamPage />
            </SuspensedView>
          }
        />
        <Route
          path='event/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='activity/*'
          element={
            <SuspensedView>
              <ActivityPage />
            </SuspensedView>
          }
        />
        <Route
          path='gemology/*'
          element={
            <SuspensedView>
              <GemologyPage />
            </SuspensedView>
          }
        />
        <Route
          path='gemologyphoto/*'
          element={
            <SuspensedView>
              <GemologyPhotoPage />
            </SuspensedView>
          }
        />
        <Route
          path='gemprice/*'
          element={
            <SuspensedView>
              <GemPricePage />
            </SuspensedView>
          }
        />
        <Route
          path='repairprice/*'
          element={
            <SuspensedView>
              <RepairPricePage />
            </SuspensedView>
          }
        />
        <Route
          path='goldreport/*'
          element={
            <SuspensedView>
              <GoldReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='goldreportpage/*'
          element={
            <SuspensedView>
              <GoldReportPagePage />
            </SuspensedView>
          }
        />
        <Route
          path='advertisement/*'
          element={
            <SuspensedView>
              <AdvertisementPage />
            </SuspensedView>
          }
        />
        <Route
          path='employeead/*'
          element={
            <SuspensedView>
              <EmployeeAdPage />
            </SuspensedView>
          }
        />
        <Route
          path='storead/*'
          element={
            <SuspensedView>
              <StoreAdPage />
            </SuspensedView>
          }
        />
        <Route
          path='storeadphoto/*'
          element={
            <SuspensedView>
              <StoreAdPhotoPage />
            </SuspensedView>
          }
        />
        <Route
          path='materialad/*'
          element={
            <SuspensedView>
              <MaterialAdPage />
            </SuspensedView>
          }
        />
        <Route
          path='materialadphoto/*'
          element={
            <SuspensedView>
              <MaterialAdPhotoPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationad/*'
          element={
            <SuspensedView>
              <ApplicationAdPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationform/*'
          element={
            <SuspensedView>
              <ApplicationFormPage />
            </SuspensedView>
          }
        />
        <Route
          path='goldprice/*'
          element={
            <SuspensedView>
              <GoldPricePage />
            </SuspensedView>
          }
        />
        <Route
          path='advert/*'
          element={
            <SuspensedView>
              <AdvertPage />
            </SuspensedView>
          }
        />
        <Route
          path='sliderphoto/*'
          element={
            <SuspensedView>
              <SliderPhotoPage />
            </SuspensedView>
          }
        />

        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
